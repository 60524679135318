import { TypedUseSelectorHook, useSelector } from 'react-redux';
import type { RootState } from '../store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useCartSelectors = () => {
  const appSelector = useAppSelector;

  return {
    isLoading: appSelector((state: RootState) => state.cart.loader),
    isCartOpen: appSelector((state: RootState) => state.cart.isCartOpen),
    selectedProduct: appSelector((state: RootState) => state.cart.selectedProduct),
    cartProducts: appSelector((state: RootState) => state.cart.cartProducts),
    cartUpsells: appSelector((state: RootState) => state.cart.cartProducts?.upsells),
    UpsellModal: appSelector((state: RootState) => state.cart.upsellModal),
    totalPrice: appSelector((state: RootState) => state.cart.finalPrice),
    totalSavings: appSelector((state: RootState) => state.cart.totalSavings),
    subTotal: appSelector((state: RootState) => state.cart.subTotal),
    productsQuantity: appSelector((state: RootState) => state.cart.productsQuantity),
    withShippingProtection: appSelector((state: RootState) => state.cart.withShippingProtection),
    freeShippingPrice: appSelector((state: RootState) => state.cart.freeShippingPrice),
    CartData: appSelector((state: RootState) => state.cart),
    LazyLoad: appSelector((state: RootState) => state.cart.loadLazyComponents),
  };
};
