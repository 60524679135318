import s from './LinkList.module.scss';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartActions } from 'lib/cartState/actions';

export const LinkList = () => {
  const { header } = usewpdataSelectors();
  const { loadLazyComponentsAction } = useCartActions();

  const handleZendeskClick = () => {
    window.zE.show();
    window.zE.activate();
  };

  return (
    <>
      {header.headerNavigationLinks &&
        header.headerNavigationLinks.map((item, index) => {
          const zendesk = item.custom_link?.custom_link?.includes('zendesk');

          if (item.link_type !== 'custom') {
            return (
              <li className={`${s.linkList__listItem} ${index === 0 ? 'linkList__firstChild' : ''}`} key={index}>
                <a
                  href={item.page_link?.page_link_uri}
                  className={`${s.linkList__listLink} ${s.linkList__listItemText}`}
                >
                  {item.page_link?.tr_page_link_label}
                </a>
              </li>
            );
          }

          if (item.link_type === 'custom') {
            if (zendesk) {
              return (
                <li className={`${s.linkList__listItem} ${index === 0 ? 'linkList__firstChild' : ''}`} key={index}>
                  <button
                    style={{ background: 'none', border: 'none', cursor: 'pointer', margin: '0 auto' }}
                    className={s.linkList__listItemText}
                    onClick={() => {
                      handleZendeskClick();
                    }}
                  >
                    <span className={s.linkList__listItemText} style={{ color: '#404040' }}>
                      {item.custom_link?.tr_custom_link_label}
                    </span>
                  </button>
                </li>
              );
            } else if (item.custom_link?.custom_link?.includes('#')) {
              const onReviewsClick = () => {
                loadLazyComponentsAction();
                setTimeout(() => {
                  const sectionToScroll = document.querySelector(`${item.custom_link.custom_link}`);
                  if (sectionToScroll) {
                    sectionToScroll.scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }
                }, 2000);
              };
              return (
                <li className={`${s.linkList__listItem} ${index === 0 ? 'linkList__firstChild' : ''}`} key={index}>
                  <button
                    style={{ background: 'none', border: 'none', cursor: 'pointer', margin: '0 auto' }}
                    onClick={() => onReviewsClick()}
                    className={s.linkList__listItemText}
                  >
                    <span className={s.linkList__listItemText}>{item.custom_link?.tr_custom_link_label}</span>
                  </button>
                </li>
              );
            } else {
              return (
                <li className={`${s.linkList__listItem} ${index === 0 ? 'linkList__firstChild' : ''}`} key={index}>
                  <a
                    href={item.custom_link?.custom_link}
                    className={`${s.linkList__listLink} ${s.linkList__listItemText}`}
                  >
                    {item.custom_link?.tr_custom_link_label}
                  </a>
                </li>
              );
            }
          }

          return null;
        })}
    </>
  );
};
