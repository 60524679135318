import React, { useState, useEffect } from 'react';
import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import { useCartSelectors } from 'lib/cartState/selectors';
import Button from '../Button/CTA/Button';
import parse from 'html-react-parser';
import './floating-bar.scss';

const FloatingCTA = () => {
  const { Floater, Theme, Settings } = usewpdataSelectors();
  const [timerLabel, setTimerLabel] = useState<string>(Floater?.timerLabel ?? '');
  const initialTime = 30 * 60;
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const { CartData } = useCartSelectors();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const minutes = Math.floor((timeLeft % 3600) / 60)
      .toString()
      .padStart(2, 0);
    const seconds = (timeLeft % 60).toString().padStart(2, 0);
    const adjustedTime = Floater?.timerLabel?.replace('###react_time###', ` ${minutes}:${seconds} `) ?? '';
    setTimerLabel(adjustedTime);
  }, [timeLeft]);

  return (
    <section
      id="floating-cta"
      style={{ background: Floater.styles.background }}
      className={`floating-cta ${CartData.isHeroVisible && 'floating-cta--active'}`}
    >
      <div className="floating-cta__wrapper">
        <div className="floating-cta__content">
          {Floater.floaterImage && (
            <img
              className="floating-cta__contentImage mon-img-64-w"
              src={Floater.floaterImage}
              alt={Settings.brandName}
            />
          )}

          <div className="floating-cta__contentRating">
            <img
              src={`${window.template_root}/stars/${Floater.starsImage}.svg`}
              className="floater__image--stars coarse"
              alt={Settings.brandName}
            />
            <span className="floating-cta__verbalScore" style={{ color: Floater.styles.textColorSecondary }}>
              {parse(Floater?.verbalScore)}
            </span>
          </div>
          <div
            className={`floating-cta__contentText mobile-none`}
            style={{ color: Floater.styles.textColorPrimary }}
            data-theme={Theme.heading}
          >
            {Floater.floaterHeading}
          </div>
        </div>
        <div className="floating-cta__ctaWrapper">
          <Button
            text={Floater.ctaLabel}
            data-cy="cta-floater"
            style={{
              background: Floater.styles.buttonBackground,
              '--floater-button-hover': Floater.styles.buttonHoverBackground,
            }}
            className="floating-cta__cta"
          />
          <p className="floating-cta__timer" style={{ color: Floater.styles.textColorSecondary }}>
            <img src={`${window.template_root}/images/icons/shipping.svg`} alt={Settings.brandName} />
            {parse(timerLabel)}
          </p>
        </div>
      </div>
    </section>
  );
};

export default FloatingCTA;
