import { usewpdataSelectors } from 'lib/wpdataState/selectors';
import s from './QuantityCards.module.scss';
import useItemsLeftCountDown from 'utils/itemsLeftCountdown';

const StockCountdown = ({ text }: { text: string }) => {
  const { hero, Settings } = usewpdataSelectors();

  let parts;
  const matchingText = text && text.match(/(\D+)(\d+)(.*)/);

  if (matchingText) {
    parts = matchingText.slice(1).map((part: string) => part.trim());
  }

  const dynamicNumber = parts ? useItemsLeftCountDown(Number(parts[1])) : 30;

  return (
    <div className={s.description} style={{ color: hero.styles.textColorPrimary }}>
      <div className={s.stock__bubble}></div>
      {parts && <span style={{ color: '#DE5353' }}>{`${parts[0]} ${dynamicNumber} ${parts[2]}`}</span>}
    </div>
  );
};

export default StockCountdown;
